import styled from 'styled-components';
import { FlexColumn } from '../../../styled/common';

export const StyledLayout = styled(FlexColumn)`
  min-height: 100vh;
  background-color: ${({ theme: { colors } }) => colors.background.primary};
  ${({ theme, isScroll, isWebView }) =>
    isScroll &&
    isWebView &&
    `border-bottom: 0.5px solid ${theme.border.primary}`};

  @media screen and (orientation: landscape) {
    min-height: 320px;
  }
`;
