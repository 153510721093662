import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { StyledLayout } from './styled';

export const MainLayout = ({ language, isScroll, isWebView, children }) => (
  <StyledLayout
    className={cn(`locale-${language}`, {
      scrolling: isScroll && isWebView
    })}
  >
    {children}
  </StyledLayout>
);

MainLayout.propTypes = {
  language: PropTypes.string,
  isScroll: PropTypes.bool,
  isWebView: PropTypes.bool,
  children: PropTypes.node
};
