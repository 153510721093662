export const BUILDING_TYPE_ID = {
  FLAT_ALL: '1',
  FLAT_NEW: '2',
  FLAT_SECONDARY: '3',
  HOUSE: '5',
  OFFICE: '7',
  GARAGE: '8',
  AREA: '9',
  OBJECT: '10'
};

export const MODAL_ANIMATION_TIME = 300;

export const urlHashes = {
  authentication: '#authentication',
  more: '#more',
  serviceBump: '#service_bump',
  serviceFeature: '#service_feature',
  serviceVip: '#service_vip',
  restore: '#restore',
  personalAds: '#personal_ads',
  limitPayment: '#limit_payment'
};

export const shortPromotionHashes = [
  urlHashes.serviceBump,
  urlHashes.serviceFeature,
  urlHashes.serviceVip
];

export const longPromotionHashes = [
  urlHashes.serviceBump,
  urlHashes.serviceFeature,
  urlHashes.serviceVip,
  urlHashes.restore,
  urlHashes.personalAds,
  urlHashes.limitPayment
];

export const FORM_VALUES = {
  leased: 'isLeased',
  cityId: 'city_id',
  villageId: 'townshipId',
  villageName: 'villageName',
  categoryId: 'categoryId',
  locationId: 'regionId',
  locationIds: 'landmarkIds',
  locationName: 'locationName',
  coordinates: 'coordinates',
  address: 'address',
  roomsAmount: 'rooms',
  area: 'area',
  landArea: 'landArea',
  floor: 'floor',
  totalFloors: 'totalFloors',
  hasRepair: 'hasRepair',
  buildingType: 'buildingType',
  paidDaily: 'paidDaily',
  price: 'price',
  hasBillOfSale: 'hasBillOfSale',
  hasMortgage: 'hasMortgage',
  description: 'description',
  owner: 'owner',
  name: 'name',
  email: 'email',
  phone: 'phone',
  company: 'company',
  code: 'code',
  typeEstate: 'typeEstate',
  images: 'photos'
};
